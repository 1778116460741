import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap"
import AddReviews from "./addReviews"
import ReviewsSetting from "./reviewsSetting"
import EditReviews from "./editReviews"
import AxiosInstance from "../../../../services/axiosInstance"

const Reviews = () => {
  const [addReviews, setAddReviews] = useState(false),
    [setting, setSetting] = useState(false),
    [editReviews, setEditReviews] = useState(false),
    [reviews, setReviews] = useState([]),
    [review, setReview] = useState(null)

  const loadReviews = async () => {
    const response = await AxiosInstance.get("/settings/general-setting/by-type/review")

    if (response.status === 200 && response.data.success) {
      setReviews(response.data.data)
    }
  }

  useEffect(() => {
    loadReviews()

    return () => {}
  }, [])

  const handleAdd = () => {
    setAddReviews(true)
    setEditReviews(false)
  }
  const handleSetting = () => {
    setSetting(true)
    setEditReviews(false)
  }
  const handleEdit = review => {
    setEditReviews(true)
    setAddReviews(false)
    setSetting(false)
    setReview(review)
  }

  const handleDelete = async id => {
    const response = await AxiosInstance.delete(`/settings/general-setting/${id}`)

    if (response.status === 200) {
      setReviews(reviews.filter(element => element.id !== id))
    }
  }

  return (
    <Accordion.Item eventKey="5">
      <Accordion.Header data-bs-target="#reviewsReferralsSetting" aria-controls="reviewsReferralsSetting">
        <i className="bi bi-star-fill me-2"></i>
        Reviews, Referrals, Reputation
        <span>
          <OverlayTrigger placement="top" overlay={<Tooltip>Reviews & Reputation Monitoring</Tooltip>}>
            <i className="bi bi-question-circle-fill ms-1"></i>
          </OverlayTrigger>
        </span>
      </Accordion.Header>
      <Accordion.Body
        id="reviewsReferralsSetting"
        aria-labelledby="reviewsReferralsSetting"
        data-bs-parent="#generalSettings"
      >
        <div className="row p-3">
          <div className="col-lg-10 col-sm-12 col-md-12 col-xs-12 mx-auto">
            {addReviews == true && <AddReviews setAddReviews={setAddReviews} setReviews={setReviews} />}
            {editReviews == true && (
              <EditReviews
                setEditReviews={setEditReviews}
                review={review}
                setReview={setReview}
                setReviews={setReviews}
              />
            )}
            {setting == true && <ReviewsSetting setSetting={setSetting} />}
            <div className="row mb-2 align-items-center">
              <div className="col-md-6">
                <h4 className="mb-0">
                  <i className="bi bi-star-fill"></i> Websites with reviews of your business
                </h4>
              </div>
              <div className="col-md-6 text-end">
                <button className="btn btn-default btnAddNewReviewSettings" onClick={handleSetting}>
                  <i className="bi bi-gear-fill"></i>
                </button>{" "}
                <button className="btn btn-primary btnAddNewReviewLocation" onClick={handleAdd}>
                  <i className="bi bi-plus-lg"></i>
                  Add New
                </button>
              </div>
            </div>
            <div className="table-responsive">
              <table id="reviewsOfBusiness" className="table table-striped dataTable no-footer" width="100%">
                <thead>
                  <tr>
                    <th width="80%">Review Location</th>
                    <th width="20%"></th>
                  </tr>
                </thead>
                <tbody>
                  {reviews.length === 0 && (
                    <tr>
                      <td colSpan={2} className="text-center">
                        No review websites added yet - Want to
                        <a
                          style={{ color: "#125fad", cursor: "pointer" }}
                          onClick={() => setAddReviews(true)}
                        >
                          {" "}
                          click here to add one{" "}
                        </a>{" "}
                        now?
                      </td>
                    </tr>
                  )}

                  {reviews.length !== 0 &&
                    reviews.map(review => {
                      return (
                        <tr key={review.id}>
                          <td className="text-left">
                            <Link to={review.value} target="_blank">
                              {review.value}
                            </Link>{" "}
                          </td>
                          <td className="text-end tableColAction d-none d-md-table-cell">
                            <a
                              className="btn btn-edit"
                              onClick={() => {
                                handleEdit(review)
                              }}
                            >
                              <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
                                <i className="bi bi-pencil-fill" />
                              </OverlayTrigger>
                            </a>{" "}
                            <a
                              className="btn btn-delete"
                              onClick={() => {
                                handleDelete(review.id)
                              }}
                            >
                              <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                                <i className="bi bi-trash-fill" />
                              </OverlayTrigger>
                            </a>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}

export default Reviews
